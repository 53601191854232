import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BigPlayButton, Player } from 'video-react';
import PropTypes from 'prop-types';

import { getGraphCMSVideoUrl } from '../../utils';
import DownloadIconRed from '../../images/DownloadIconRed.png';

/**
 * @return {null}
 */
const InlinePlayer = ({ videos, dobiesTitle, dobiesOrder }) => {
  const [selected, setSelected] = useState(videos[0]);
  if (!selected) {
    return null;
  }

  return (
    <Wrapper>
      <WelcomeDobiesHeading>
        <WelcomeDobiesHeadingOrder>{dobiesOrder}</WelcomeDobiesHeadingOrder>
        <WelcomeDobiesHeadingTitle>{dobiesTitle}</WelcomeDobiesHeadingTitle>
      </WelcomeDobiesHeading>
      <PlayingContainer>
        <Player
          src={getGraphCMSVideoUrl(selected)}
          poster={selected.poster && selected.poster.url}
        >
          <BigPlayButton position="center" />
        </Player>
        <PlayingVideoTitle>{selected.title}</PlayingVideoTitle>
        {selected.descriptions[0] && (
          <p
            dangerouslySetInnerHTML={{ __html: selected.descriptions[0].html }}
          />
        )}
      </PlayingContainer>

      <ASide>
        {videos
          .filter(video => video.id !== selected.id)
          .map(video => (
            <AsideVideo key={video.id}>
              <Player
                src={getGraphCMSVideoUrl(video)}
                preload={false}
                poster={video.poster && video.poster.url}
              >
                <BigPlayButton disabled />
              </Player>
              <PlayingVideoTitle aside>
                <p
                  onClick={e => {
                    e.preventDefault();
                    setSelected(video);
                  }}
                >
                  {video.title}
                </p>
                {video.file && (
                  <Download href={`${video.file.url}`}>
                    {video.descriptions[0].text}
                  </Download>
                )}
              </PlayingVideoTitle>
            </AsideVideo>
          ))}
      </ASide>
    </Wrapper>
  );
};
const WelcomeDobiesHeading = styled.div`
  text-align: center;
  width: 100%;
  padding: 10px 0;
`;
const WelcomeDobiesHeadingOrder = styled.span`
  color: #d22222;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const WelcomeDobiesHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const AsideVideo = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 1em;
  margin-bottom: 1em;

  @media screen and (min-width: 769px) {
    cursor: pointer;
    grid-template-columns: 176px 1fr;
    grid-template-rows: 100px;
  }
`;

const PlayingContainer = styled.div`
  width: 100%;
  min-height: 250px;
  max-height: 360px;
  max-width: 650px;
  margin-bottom: 2.5em;
  display: block;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 19px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px 10px;
  a {
    color: #d22222;
    text-decoration: none;
  }
`;

const PlayingVideoTitle = styled.p`
  color: #354149;
  font-size: 14px;
  margin: 0.5em 0;
  font-weight: 300;

  @media screen and (min-width: 769px) {
    ${({ aside }) =>
      !aside &&
      css`
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
      `};
  }

  ${({ aside }) =>
    aside &&
    css`
      font-size: 14px;
      margin: 0;
    `};
`;

const ASide = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const Wrapper = styled.div`
  padding: 0 1em;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const Download = styled.a`
  background-color: transparent;
  position: relative;
  margin-left: 20px;
  color: #d22222;
  font-size: 16px;
  width: auto;
  margin-bottom: 0;
  &:hover {
    background-color: transparent;
  }
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: -22px;
    width: 14px;
    height: 19px;
    background-image: url(${DownloadIconRed});
  }
`;
InlinePlayer.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})),
  dobiesTitle: PropTypes.string.isRequired,
  dobiesOrder: PropTypes.string.isRequired
};

InlinePlayer.defaultProps = {
  videos: []
};

export default InlinePlayer;
