import PropTypes from 'prop-types';
import React from 'react';
import faceIcon from '../../images/facebook-icon.png';
import styled from 'styled-components';
import telIcon from '../../images/Tel-icon.png';

const WelcomeConsult = ({ data }) => {
  if (!data) return null;
  const { order, title, descriptions } = data;
  return (
    <WelcomeResultContent>
      <WelcomeResultContentHeading>
        <WelcomeResultContentHeadingOrder>
          {order}
        </WelcomeResultContentHeadingOrder>
        <WelcomeResultContentHeadingTitle>
          {title}
        </WelcomeResultContentHeadingTitle>
      </WelcomeResultContentHeading>
      {descriptions && (
        <WelcomeDescription>
          <p dangerouslySetInnerHTML={{ __html: descriptions[0].html }} />
          {descriptions[1] && (
            <InfoTelDescription>
              <li dangerouslySetInnerHTML={{ __html: descriptions[1].html }} />
            </InfoTelDescription>
          )}
          {descriptions[2] && (
            <InfoFaceDescription>
              <li dangerouslySetInnerHTML={{ __html: descriptions[2].html }} />
            </InfoFaceDescription>
          )}
        </WelcomeDescription>
      )}
    </WelcomeResultContent>
  );
};

const WelcomeDescription = styled.div`
    p {
      font-size: 14px;
      @media screen and (min-width: 769px) {
        font-size: 16px;
      }
      a {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: underline;
      }
    }
  }
`;
const InfoTelDescription = styled.ul`
  margin-bottom: 0;
  margin-top: 15px;
    li{
      position: relative;
      list-style-type: none;
    }
    li:before {
        content: "";
        position: absolute;
        top: 2px;
        left: -22px;
        width: 19px;
        height: 19px;
        background-image: url(${telIcon});
      p {
        font-size: 16px;
      }
    }
  }
`;
const InfoFaceDescription = styled.ul`
  margin-bottom: 0;
  li{
      position: relative;
      list-style-type: none;
    }
    li:before {
        content: "";
        position: absolute;
        top: 6px;
        left: -16px;
        width: 7px;
        height: 11px;
        background-image: url(${faceIcon});
      p {
        font-size: 16px;
      }
    }
  }
`;

const WelcomeResultContent = styled.div`
  padding: 1em 2em;
  @media screen and (min-width: 769px) {
    flex: 1;
  }
`;
const WelcomeResultContentHeading = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  @media screen and (min-width: 769px) {
    font-size: 2.25em;
  }
`;
const WelcomeResultContentHeadingOrder = styled.span`
  color: #d22222;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const WelcomeResultContentHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
export default WelcomeConsult;
WelcomeConsult.propTypes = {
  data: PropTypes.any
};
