import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import styled from 'styled-components';

import Container from '../components/Container';
import { MainLayout } from '../components/Layout';
import SEO from '../components/SEO';
import WelcomeApp from '../components/Welcome/WelcomeApp';
import WelcomeConsult from '../components/Welcome/WelcomeConsult';
import WelcomeDobies from '../components/Welcome/WelcomeDobies';
import WelcomeMakeTest from '../components/Welcome/WelcomeMakeTest';
import WelcomeResult from '../components/Welcome/WelcomeResult';
import WelcomeSection from '../components/Welcome/WelcomeSection';
import withI18next from '../components/withI18next';
import welcomeFooterCirclepBg from '../images/WelcomeFooterDestop-bg.png';

const Welcome = ({ data, pageContext }) => {
  if (!data) return null;
  const { seos, pageWelcomes } = data.gcms;
  const seo = seos[0];
  const pageWelcome = pageWelcomes[0];
  return (
    <MainLayout>
      {!!seo && (
        <SEO
          title={seo.title}
          keywords={seo.keywords}
          description={seo.description}
          meta={pageContext.meta}
        />
      )}
      <Container bgc="#ffffff">
        <WelcomeSection introData={pageWelcome} />
      </Container>
      <Container bgc="linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)">
        <WelcomeApp data={pageWelcome.welcomeAppDownload} />
      </Container>
      <Container bgc="linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)">
        <WelcomeMakeTest data={pageWelcome.welcomeTesting} />
      </Container>
      <Container bgc="linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)">
        <WelcomeResultWrapper>
          <WelcomeResult data={pageWelcome.welcomeResult} />
          <WelcomeConsult data={pageWelcome.welcomeConsult} />
          <WelcomeResult data={pageWelcome.welcomeRegist} />
        </WelcomeResultWrapper>
      </Container>
      <Container bgc="linear-gradient(180deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%)">
        <WelcomeDobies data={pageWelcome} />
      </Container>
      <Container>
        <WelcomeFooter data={pageWelcome.welcomeFooter} />
      </Container>
    </MainLayout>
  );
};
const WelcomeFooter = ({ data }) => {
  if (!data) return null;
  return (
    <WelcomeFooterWrapper>
      <div
        className="wc-ft-content"
        dangerouslySetInnerHTML={{ __html: data.descriptions[0].html }}
      />
      <WelcomeFooterBG>
        {data.asset.mobile && (
          <ReviewImage src={data.asset.mobile.url} alt="" />
        )}
        {data.asset.wide && (
          <ReviewImageDestop src={data.asset.wide.url} alt="" />
        )}
      </WelcomeFooterBG>
      <WelcomeFooterCircleBG />
    </WelcomeFooterWrapper>
  );
};
const WelcomeFooterWrapper = styled.div`
  background-color: #d22222;
  padding: 35px 30px;
  position: relative;
  div.wc-ft-content {
    max-width: 180px;
    color: #fff;
    font-size: 1.35em;
    font-weight: 700;
    @media screen and (min-width: 769px) {
      max-width: 45%;
      font-size: 2.25em;
      margin-left: 15%;
      margin-right: 40%;
    }
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  @media screen and (min-width: 769px) {
    margin-top: 160px;
  }
`;
const WelcomeFooterBG = styled.div`
  width: 180px;
  height: 189px;
  position: absolute;
  right: 0;
  top: -20px;
  @media screen and (min-width: 769px) {
    width: 312px;
    height: 308px;
    right: 190px;
    top: -122px;
  }
`;
const WelcomeFooterCircleBG = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    background-image: url(${welcomeFooterCirclepBg});
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto;
    width: 170px;
    height: 290px;
    position: absolute;
    right: 0;
    top: -170px;
  }
`;
const ReviewImage = styled.img`
  display: block;
  margin: 0 auto;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const ReviewImageDestop = styled.img`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    margin: 0 auto;
  }
`;
const WelcomeResultWrapper = styled.div`
  @media screen and (min-width: 769px) {
    padding: 2em 5.5em 0;
    display: flex;
    flex-direction: row;
  }
`;
export default withI18next({ ns: 'welcome' })(Welcome);
Welcome.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.any
  }),
  pageContext: PropTypes.shape({
    meta: PropTypes.any
  })
};
WelcomeFooter.propTypes = {
  data: PropTypes.shape({
    asset: PropTypes.shape({
      mobile: PropTypes.shape({
        url: PropTypes.any
      }),
      wide: PropTypes.shape({
        url: PropTypes.any
      })
    }),
    descriptions: PropTypes.any
  })
};
export const query = graphql`
  query fetchWelcome {
    gcms {
      seos(where: { path: "welcome" }) {
        path
        title
        description
        keywords
      }
      pageWelcomes(stage: PUBLISHED) {
        hero {
          ...CTACard
        }
        welcomeAppDownload {
          ...CTACard
        }
        welcomeResult {
          ...CTACard
        }
        welcomeTesting {
          ...CTACard
        }
        welcomeConsult {
          ...CTACard
        }
        welcomeRegist {
          ...CTACard
        }
        welcomeDobies {
          ...CTACard
        }
        welcomeDobiesVideo {
          ...CTACard
        }
        welcomeFooter {
          ...CTACard
        }
      }
    }
  }
`;
