import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

const DownloadFile = ({ label, url, ...rest }) => {
  const { t } = useTranslation('welcome');

  return (
    <Download
      download
      href={`${url}?dl=true`}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <DownloadIcon />
      <DownloadIconDestop />
      {t(label)}
    </Download>
  );
};

const DownloadIcon = styled(Icon).attrs({ type: 'WelcomeDownloadIcon' })`
  color: #fff;
  margin-right: 0.5em;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const DownloadIconDestop = styled(Icon).attrs({
  type: 'WelcomeDownloadIconBlack'
})`
  color: #000;
  margin-right: 0.5em;
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
  }
`;
const Download = styled.a`
  color: white;
  text-decoration: none;
  background-color: #2d3942;
  padding: 0.5em 1em;
  border-radius: 80px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  transition: background-color 0.5s;
  margin-bottom: 15px;
  width: 100%;
  &:hover {
    background-color: #000;
  }
  @media screen and (min-width: 769px) {
    background-color: transparent;
    color: #000;
    font-weight: 900;
    font-size: 16px;
    width: auto;
    margin-bottom: 0;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
`;

DownloadFile.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string.isRequired
};

DownloadFile.defaultProps = {
  label: 'Download PDF'
};

export default DownloadFile;
