import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useWindowSize from '../../utils/hooks/useWindowSize';
import welcomeTopBg from '../../images/welcome-top-bg.png';
import welcomeTopMbBg from '../../images/welcome-top-mb-bg.png';

const WelcomeSection = ({ introData }) => {
  const w = useWindowSize();
  if (w.innerWidth === 0) {
    return null;
  }
  const imgType = w.innerWidth <= 768 ? 'mobile' : 'wide';
  let imgPath = '';
  if (imgType === 'mobile') {
    imgPath = introData.hero.asset.mobile.url;
  } else {
    imgPath = introData.hero.asset.wide.url;
  }

  function createMarkup() {
    return { __html: introData.hero.descriptions[0].html };
  }
  return (
    <WelcomeIntroWrapper>
      <WelcomeIntro>
        <WelcomeIntroHeadingFirst>
          {introData.hero.slogan}
        </WelcomeIntroHeadingFirst>
        <WelcomeIntroHeadingSecond>
          {introData.hero.title}
        </WelcomeIntroHeadingSecond>
        <WelcomeDescription>
          <p dangerouslySetInnerHTML={createMarkup()} />
        </WelcomeDescription>
      </WelcomeIntro>
      <WelcomeImage>
        <ReviewImage src={imgPath} alt="" />
      </WelcomeImage>
    </WelcomeIntroWrapper>
  );
};
export default WelcomeSection;

const WelcomeIntroWrapper = styled.div`
  background-image: url(${welcomeTopMbBg});
  background-repeat: no-repeat;
  background-position: 0 15px;
  @media screen and (min-width: 769px) {
    background-image: url(${welcomeTopBg});
    padding: 2em 5.5em 0;
    display: grid;
    grid-template-columns: 5fr 5fr;
    grid-gap: 2em;
    background-position: 0 100px;
  }
`;
const WelcomeIntro = styled.div`
  padding: 2.5em 2.8em;
  text-align: center;
  @media screen and (min-width: 769px) {
    text-align: left;
    padding: 2.5em 2.8em 0;
  }
`;
const WelcomeIntroHeadingFirst = styled.h1`
  font-size: 2.34em;
  font-weight: 200;
  line-height: 1.3em;
  margin: 0;
  @media screen and (min-width: 769px) {
    font-size: 4em;
    font-weight: 200;
  }
`;
const WelcomeIntroHeadingSecond = styled.h1`
  font-size: 2.4em;
  font-weight: 800;
  line-height: 1.5em;
  margin: 0;
  @media screen and (min-width: 769px) {
    font-size: 4em;
    font-weight: 800;
  }
`;
const WelcomeImage = styled.div`
  @media screen and (min-width: 769px) {
  }
`;
const WelcomeDescription = styled.div`
  font-size: 1em;
  line-height: 2em;
  font-weight: 700;
  @media screen and (min-width: 769px) {
    font-size: 1.5em;
  }
`;
const ReviewImage = styled.img`
  display: block;
  margin: 0 auto;
`;
WelcomeSection.propTypes = {
  introData: PropTypes.shape({
    hero: PropTypes.shape({
      asset: PropTypes.shape({
        mobile: PropTypes.shape({
          url: PropTypes.any
        }),
        wide: PropTypes.shape({
          url: PropTypes.any
        })
      }),
      descriptions: PropTypes.any,
      slogan: PropTypes.any,
      title: PropTypes.any
    })
  })
};
