import { useEffect, useState } from 'react';

import { debounce } from 'lodash';
import useSSR from 'use-ssr';

export function useSize() {
  const { isBrowser } = useSSR();
  if (isBrowser) {
    return {
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      outerHeight: window.outerHeight,
      outerWidth: window.outerWidth
    };
  }

  return {
    innerHeight: 0,
    innerWidth: 0,
    outerHeight: 0,
    outerWidth: 0
  };
}

export function useWindowSize(cb = () => {}) {
  const size = useSize();
  const [windowSize, setWindowSize] = useState(size);

  useEffect(() => {
    function handleResize() {
      setWindowSize(size);
      cb(size);
    }
    const debouncedHandler = debounce(handleResize, 1000);
    window.addEventListener('resize', debouncedHandler);
    return () => {
      window.removeEventListener('resize', debouncedHandler);
    };
  }, [size, cb]);

  return windowSize;
}

export default useWindowSize;

export const useWindowResize = useWindowSize;
