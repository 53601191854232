import Icon from '../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const WelcomeApp = ({ data }) => {
  const { order, title, descriptions } = data;
  return (
    <WelcomeAppWrapper>
      <WelcomeAppContent>
        <WelcomeAppContentHeading>
          <WelcomeAppContentHeadingOrder>{order}</WelcomeAppContentHeadingOrder>
          <WelcomeAppContentHeadingTitle>{title}</WelcomeAppContentHeadingTitle>
        </WelcomeAppContentHeading>
        {descriptions && (
          <WelcomeDescription>
            {descriptions.map((des, idx) => (
              <li key={`des-${idx}`}>
                <BenefitIcon type="WelcomListIcon" />
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: des.html }} />
              </li>
            ))}
          </WelcomeDescription>
        )}
      </WelcomeAppContent>
      <WelcomeAppVideo>
        {data.asset.wide.url && <video src={data.asset.wide.url} controls />}
      </WelcomeAppVideo>
    </WelcomeAppWrapper>
  );
};
const WelcomeAppWrapper = styled.div`
  @media screen and (min-width: 769px) {
    padding: 35px 5.5em 28px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;
const WelcomeAppVideo = styled.div`{
  text-align: center;
  video {
    max-width: 100%;
    height: auto;
  }
  padding: 1em;
  @media screen and (min-width: 769px) {
   text-align: left;
   width:45%;
   height: auto;
  }
`;
const WelcomeDescription = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  li {
    display: flex;
    align-items: baseline;
    font-weight: normal;
    p {
      font-size: 14px;
      @media screen and (min-width: 769px) {
        font-size: 18px;
      }
      a {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: underline;
      }
    }
  }
`;
const BenefitIcon = styled(Icon)`
  font-size: 10px;
  margin-right: 15px;
`;
const WelcomeAppContent = styled.div`
  padding: 1em 2em;
  @media screen and (min-width: 769px) {
    width: 60%;
  }
`;
const WelcomeAppContentHeading = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  @media screen and (min-width: 769px) {
    font-size: 2.25em;
  }
`;
const WelcomeAppContentHeadingOrder = styled.span`
  color: #d22222;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const WelcomeAppContentHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
export default WelcomeApp;
WelcomeApp.propTypes = {
  data: PropTypes.shape({
    order: PropTypes.number,
    title: PropTypes.string,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    asset: PropTypes.shape({
      wide: PropTypes.shape({
        url: PropTypes.string
      })
    })
  })
};
