import DownloadIconRed from '../../images/DownloadIconRed.png';
import InlinePlayer from './InlinePlayer';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const WelcomeDobies = ({ data }) => {
  const { order, title } = data.welcomeDobies;
  const videos = data.welcomeDobiesVideo;
  return (
    <>
      <WelcomeDobiesWrapper>
        <WelcomeDobiesHeading>
          <WelcomeDobiesHeadingOrder>{order}</WelcomeDobiesHeadingOrder>
          <WelcomeDobiesHeadingTitle>{title}</WelcomeDobiesHeadingTitle>
        </WelcomeDobiesHeading>
        {videos && (
          <DobiesVideosSection>
            {videos.map((video, idx) => (
              <DobiesItem key={`video${idx}`}>
                <VideoWrapper>
                  {video.asset.wide && (
                    <video controls src={video.asset.wide.url} />
                  )}
                </VideoWrapper>
                <ContentWrapper>
                  <p>{video.title}</p>
                  {video.descriptions[0] && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: video.descriptions[0].html
                      }}
                    />
                  )}
                  {video.asset.square && (
                    <Download href={`${video.asset.square.url}`}>
                      {video.descriptions[0].text}
                    </Download>
                  )}
                </ContentWrapper>
              </DobiesItem>
            ))}
          </DobiesVideosSection>
        )}
      </WelcomeDobiesWrapper>

      <InlinePlayer
        dobiesTitle={title}
        dobiesOrder={order}
        videos={videos.map(({ asset, title: vTitle, descriptions }) => {
          const video = asset.wide;
          video.title = vTitle;
          video.file = asset.square;
          video.descriptions = descriptions;

          return video;
        })}
      />
    </>
  );
};

const WelcomeDobiesWrapper = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    padding: 2em 5.5em 0;
  }
`;
const WelcomeDobiesHeading = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 0;
`;
const WelcomeDobiesHeadingOrder = styled.span`
  color: #d22222;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const WelcomeDobiesHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const DobiesVideosSection = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ContentWrapper = styled.div`
  width: 70%;
  font-size: 14px;
  a {
    color: #D22222;
    text-decoration: none;
  }
  @media screen and (min-width: 769px) {
    font-size: 16px;
    width: 100%;
  }
}
`;
const VideoWrapper = styled.div`
  width: 30%;
  margin-right: 5%;
  margin-bottom: 20px;
  video {
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    video {
      width: 100%;
    }
  }
`;
const DobiesItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  &:first-child {
    display: block;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    box-shadow: 19px 20px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    ${ContentWrapper}{
      width:100%;
      padding: 0 10px 15px;
    }
    ${VideoWrapper}{
      width:100%;
    }
  }
  @media screen and (min-width: 769px) {
    display: block;
    padding 15px;
    margin: 15px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    box-shadow: 19px 20px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: calc(33% - 30px);
  }
`;
const Download = styled.a`
  background-color: transparent;
  position: relative;
  margin-left: 20px;
  color: #d22222;
  font-size: 16px;
  width: auto;
  margin-bottom: 0;
  &:hover {
    background-color: transparent;
  }
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: -22px;
    width: 14px;
    height: 19px;
    background-image: url(${DownloadIconRed});
  }
`;
export default WelcomeDobies;
WelcomeDobies.propTypes = {
  data: PropTypes.shape({
    welcomeDobies: PropTypes.any,
    welcomeDobiesVideo: PropTypes.shape({
      map: PropTypes.func
    })
  })
};
