import Download from './DownloadFile';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const WelcomeResult = ({ data }) => {
  const { order, title, descriptions } = data;
  return (
    <WelcomeResultContent>
      <WelcomeResultContentHeading>
        <WelcomeResultContentHeadingOrder>
          {order}
        </WelcomeResultContentHeadingOrder>
        <WelcomeResultContentHeadingTitle>
          {title}
        </WelcomeResultContentHeadingTitle>
      </WelcomeResultContentHeading>
      {descriptions && (
        <WelcomeDescription>
          <p dangerouslySetInnerHTML={{ __html: descriptions[0].html }} />
        </WelcomeDescription>
      )}
      {data.asset.wide && (
        <Download
          url={`${data.asset.wide.url}?dl=true`}
          label={descriptions[1].text}
        />
      )}
      {data.asset.square && (
        <Download
          url={`${data.asset.square.url}?dl=true`}
          label={descriptions[1].text}
        />
      )}
    </WelcomeResultContent>
  );
};

const WelcomeDescription = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  li {
    display: flex;
    align-items: baseline;
    font-weight: normal;
    p {
      font-size: 14px;
      @media screen and (min-width: 769px) {
        font-size: 18px;
      }
      a {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: underline;
      }
    }
  }
`;

const WelcomeResultContent = styled.div`
  padding: 1em 3em;
  @media screen and (min-width: 769px) {
    flex: 1;
    text-align: left;
  }
`;
const WelcomeResultContentHeading = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  @media screen and (min-width: 769px) {
    font-size: 2.25em;
  }
`;
const WelcomeResultContentHeadingOrder = styled.span`
  color: #d22222;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const WelcomeResultContentHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
export default WelcomeResult;
WelcomeResult.propTypes = {
  data: PropTypes.shape({
    order: PropTypes.any,
    title: PropTypes.any,
    descriptions: PropTypes.any,
    asset: PropTypes.shape({
      square: PropTypes.shape({
        url: PropTypes.any
      }),
      wide: PropTypes.shape({
        url: PropTypes.any
      })
    })
  })
};
