import Icon from '../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import welcomeMakeTestCirclepBg from '../../images/WelcomeTestCircle-bg.png';
import welcomeMakeTestCirclepMbBg from '../../images/WelcomeTestCircle-mb-bg.png';

const WelcomeMakeTest = ({ data }) => {
  const { order, title, descriptions } = data;
  return (
    <WelcomeMakeTestWrMakeTester>
      <WelcomeMakeTestContent>
        <WelcomeMakeTestContentHeading>
          <WelcomeMakeTestContentHeadingOrder>
            {order}
          </WelcomeMakeTestContentHeadingOrder>
          <WelcomeMakeTestContentHeadingTitle>
            {title}
          </WelcomeMakeTestContentHeadingTitle>
        </WelcomeMakeTestContentHeading>
        {descriptions && (
          <WelcomeDescription>
            {descriptions.map((des, idx) => (
              <li key={`des-${idx}`}>
                <BenefitIcon type="WelcomListIcon" />
                <span dangerouslySetInnerHTML={{ __html: des.html }} />
              </li>
            ))}
          </WelcomeDescription>
        )}
      </WelcomeMakeTestContent>
      <WelcomeMakeTestVideo>
        {data.asset.wide && data.asset.wide.url && (
          <video src={data.asset.wide.url} autoPlay loop controls="controls" />
        )}
        <WelcomeMakeTestCircleBG />
      </WelcomeMakeTestVideo>
    </WelcomeMakeTestWrMakeTester>
  );
};
const WelcomeMakeTestWrMakeTester = styled.div`
  @media screen and (min-width: 769px) {
    padding: 35px 5.5em 28px;
    display: flex;
  }
`;
const WelcomeMakeTestVideo = styled.div`{
  position: relative;
  video {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
  }
  padding: 1em 2em;
  @media screen and (min-width: 769px) {
   width:45%;
   position: relative;
   z-index: 0;
  }
`;
const WelcomeMakeTestCircleBG = styled.div`
  background-image: url(${welcomeMakeTestCirclepMbBg});
  background-repeat: no-repeat;
  position: absolute;
  width: 110px;
  height: 115px;
  right: 0;
  top: -60px;
  @media screen and (min-width: 769px) {
    background-image: url(${welcomeMakeTestCirclepBg});
    z-index: -1;
    width: 190px;
    height: 220px;
    right: 29px;
    top: -80px;
  }
`;
const WelcomeDescription = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  li {
    display: flex;
    align-items: baseline;
    font-weight: normal;
    p {
      font-size: 14px;
      @media screen and (min-width: 769px) {
        font-size: 18px;
      }
      a {
        color: rgba(0, 0, 0, 0.7);
        text-decoration: underline;
      }
    }
  }
`;
const BenefitIcon = styled(Icon)`
  font-size: 10px;
  margin-right: 15px;
`;
const WelcomeMakeTestContent = styled.div`
  padding: 1em 2em;
  @media screen and (min-width: 769px) {
    width: 55%;
  }
`;
const WelcomeMakeTestContentHeading = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  @media screen and (min-width: 769px) {
    font-size: 2.25em;
  }
`;
const WelcomeMakeTestContentHeadingOrder = styled.span`
  color: #d22222;
  margin-right: 5px;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const WelcomeMakeTestContentHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
export default WelcomeMakeTest;
WelcomeMakeTest.propTypes = {
  data: PropTypes.shape({
    order: PropTypes.any,
    title: PropTypes.any,
    descriptions: PropTypes.any,
    asset: PropTypes.shape({
      wide: PropTypes.shape({
        url: PropTypes.any
      })
    })
  })
};
